<template>
  <div class="all">
    <div class="home">
      <div>
        <div class="bigbox">
          <img src="@/assets/images/home/homeimg.png" alt />
        </div>
        <div class="getopen" @click="$router.push({ name: 'Open' })">
          <img src="@/assets/images/home/getopen.png" alt />
        </div>
      </div>
    </div>
    <div class="phone" v-show="show == false">
      <el-carousel
        height="110px"
        class="swiper"
        indicator-position="none"
        arrow="never"
      >
        <el-carousel-item v-for="(item, index) in imgs" :key="index">
          <img :src="item.img" alt />
        </el-carousel-item>
      </el-carousel>
      <div class="nav">
        <div class="fuli" @click="fuli">
          <div class="fu-h">
            <img src="@/assets/images/home/phone/fuli.png" alt />
          </div>
          <div>福 利</div>
        </div>
        <div class="fuli" @click="roll">
          <div class="fu-h">
            <img src="@/assets/images/home/phone/roll.png" alt />
          </div>
          <div>ROll</div>
        </div>
        <div class="fuli" @click="replacing">
          <div class="fu-h">
            <img src="@/assets/images/home/phone/change.png" alt />
          </div>
          <div>汰 换</div>
        </div>
        <div class="fuli" @click="shopping">
          <div class="fu-h">
            <img src="@/assets/images/home/phone/shangcheng.png" alt />
          </div>
          <div>商 城</div>
        </div>
        <div class="fuli" @click="Recharge">
          <div class="fu-h">
            <img src="@/assets/images/home/phone/chongzhi.png" alt />
          </div>
          <div>充 值</div>
        </div>
      </div>
      <div class="botm">
        <img
          class="bigkill"
          @click="bigkill"
          src="@/assets/images/home/phone/bigkill.gif"
          alt
        />
        <img
          class="bigkillb"
          @click="bigkill"
          src="@/assets/images/home/phone/bigkill.png"
          alt
        />
        <img
          class="open"
          src="@/assets/images/home/phone/openbox.gif"
          alt
          @click="$router.push({ name: 'Open' })"
        />
        <img
          class="openb"
          src="@/assets/images/home/phone/bottom.png"
          alt
          @click="$router.push({ name: 'Open' })"
        />
        <img
          class="pk"
          src="@/assets/images/home/phone/pk.gif"
          alt
          @click="pk"
        />
        <img
          class="pkb"
          src="@/assets/images/home/phone/bottom.png"
          alt
          @click="pk"
        />
      </div>
      <div class="bottom">
        <div class="bottomtitle">
          <img src="@/assets/images/logo.png" alt />
          <p>鲁ICP备2022019242号-3</p>
          <p>盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</p>
        </div>
        <div class="main f-13 d-flex">
          <div class="yhxy" @click="(close = true), (type = 1)">
            <p>用户协议</p>
          </div>
          <div class="ysxy" @click="(close = true), (type = 0)">
            <p>隐私协议</p>
          </div>
          <div class="help" @click="(close = true), (type = 2)">
            <p>帮助中心</p>
          </div>
          <div class="about" @click="(close = true), (type = 3)">
            <p>反洗钱协议</p>
          </div>
        </div>
      </div>
      <div class="tanchuang1" v-if="close == true">
        <div @click="close = false" class="closebg"></div>
        <h2>{{ title }}</h2>
        <div v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeBanner } from "../../api/index";
import { getArticle } from "../../api/index";
import { getToken } from "../../api/cookie";

export default {
  data() {
    return {
      imgs: "",
      screenWidth: document.body.clientWidth,
      show: false,
      close: false,
      closes: false,
      type: 1,
      text: "",
      title: "",
    };
  },
  updated() {
    //文章接口
    getArticle(1).then((res) => {
      // console.log(this.type);
      this.title = res.data.rows[this.type].title;
      this.text = res.data.rows[this.type].content;
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = document.body.clientWidth;
    });
    getHomeBanner().then((res) => {
      this.imgs = res.data.rows;
    });
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler: function () {
        if (this.screenWidth > 650) {
          this.show = true;
        }
      },
    },
  },
  methods: {
    fuli() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Welfare" });
      }
    },
    replacing() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "replacing" });
      }
    },
    shopping() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Shopping" });
      }
    },
    Recharge() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$bus.$emit("chongzhi", true);
      }
    },
    roll() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "rollHome" });
      }
    },
    pk() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Pk" });
      }
    },
    bigkill() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "bigKillhome" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.ql-syntax {
  font-size: 16px;
  text-align: left !important;
  white-space: pre-wrap;
}
.all {
  .home {
    width: 80%;
    margin: 13px auto;

    div {
      .bigbox {
        width: 500px;
        height: 487px;
        margin: auto;
        background: url("~@/assets/images/home/mainBg.png") no-repeat;
        background-size: 100% 100%;

        img {
          width: 601px;
          height: 340px;
          margin-left: -100px;
          margin-top: 35px;
        }
      }

      .getopen {
        width: 169px;
        height: 50px;
        margin: auto;

        img {
          width: 169px;
          height: 50px;
        }
      }
      .tanchuang1 {
        position: fixed;
        z-index: 99;
        width: 100%;
        // height: 800px;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 10px;
        /* 毛玻璃模糊背景 */
        background-color: rgb(36, 35, 35);
        // backdrop-filter: blur(6px);
        color: white;
        text-align: center;
        font-size: 25px;
        overflow: auto;

        .closebg {
          width: 50px;
          height: 50px;
          background: url("~@/assets/roll/closs.png") no-repeat;
          background-size: 100% 100%;
          float: right;
        }
      }

      .tanchuang1::-webkit-scrollbar {
        width: 1px; //修改滚动条宽度
      }
    }
  }
}

@media (max-width: 550px) {
  .all {
    height: 100%;
    //background-color: #fff;

    .home {
      display: none;
    }

    .phone {
      width: 100%;
      margin: 10px auto;

      .swiper {
        width: 93%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .nav {
        width: 100%;
        height: 90px;
        margin-top: 10px;
        display: flex;
        justify-content: space-around;

        .fuli {
          flex: none;
          width: 43px;
          height: 100%;

          .fu-h {
            height: 40px;
            width: 40px;
            background-image: url("../../assets/images/home/phone/bk.gif");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              display: block;
              width: 60%;
              height: 60%;
            }
          }

          div {
            margin-top: 1px;
            text-align: center;
            font-size: 15px;
            color: #fff;
          }
        }
      }

      .botm {
        width: 90%;
        height: 282px;
        margin: auto;
        position: relative;
        .bigkill,
        .open,
        .pk {
          position: absolute;
          width: 100%;
          margin: 0;
          z-index: 9;
        }
        .bigkillb,
        .openb,
        .pkb {
          position: absolute;
          width: 100%;
          margin: 0;
          z-index: 8;
        }
        .bigkill {
          top: -2px;
        }
        .open {
          top: 100px;
        }
        .pk {
          top: 190px;
        }
        .bigkillb {
          top: -2px;
        }
        .openb {
          top: 102px;
        }
        .pkb {
          top: 192px;
        }
      }
      .bottom {
        width: 100%;
        min-height: auto;
        margin-bottom: 55px;
        background-color: #00000081;
        color: rgba(225, 225, 225, 0.886);
        .bottomtitle {
          text-align: center;
          margin-bottom: 10px;
          p {
            font-size: 10px;
          }
        }
        .main {
          justify-content: space-around;
        }
      }
      .tanchuang1 {
        position: fixed;
        z-index: 99;
        width: 100%;
        // height: 800px;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 10px;
        /* 毛玻璃模糊背景 */
        background-color: rgb(36, 35, 35);
        // backdrop-filter: blur(6px);
        color: white;
        text-align: center;
        font-size: 25px;
        overflow: auto;

        .closebg {
          width: 50px;
          height: 50px;
          background: url("~@/assets/roll/closs.png") no-repeat;
          background-size: 100% 100%;
          float: right;
        }
      }

      .tanchuang1::-webkit-scrollbar {
        width: 1px; //修改滚动条宽度
      }
    }
  }
}
</style>
